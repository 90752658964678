<template>
  <v-row class="fill-height" align="center" justify="center">
    <v-card max-width="600" flat class="text-center d-flex">
      <v-icon size="230" class="mr-10" color="#132d3d">mdi-sign-direction-remove</v-icon>
      <div>
        <h1 class="text-h1 font-weight-bold">404</h1>
        <h3 class="text-h4 font-weight-bold">Page not found</h3>
        <v-btn :to="{ name: 'Home' }" color="accent" class="gradient mt-10">Back To Homepage</v-btn>
      </div>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: 'PageNotFound',

  metaInfo: {
    title: 'Page Not Found'
  }
}
</script>